import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomDateTextField } from '../customDateTextField';

import { START_DATE_TEXT_FIELD_ID, END_DATE_TEXT_FIELD_ID } from './dateTextFieldIds.const';
import { TextFieldsWrapper, TextFieldWrapper } from './customDateRangeTextFields.theme';
import { ICustomDateRangeTextFieldsProps } from './сustomDateRangeTextFields.interface';

const CustomDateRangeTextFields: FC<ICustomDateRangeTextFieldsProps> = (props) => {
  const {
    dateRange,
    dateRangeHandler,
    setIsDateRangeValid,
  } = props;
  const { t } = useTranslation(['common']);

  const setStartDateHandler = useCallback((data: string): void => {
    dateRangeHandler({ from: data });
  }, [dateRangeHandler]);

  const setEndDateHandler = useCallback((data: string): void => {
    dateRangeHandler({ to: data });
  }, [dateRangeHandler]);

  const resetStartDateHandler = useCallback((): void => {
    dateRangeHandler({ from: undefined });
  }, [dateRangeHandler]);

  const resetEndDateHandler = useCallback((): void => {
    dateRangeHandler({ to: undefined });
  }, [dateRangeHandler]);

  const fromDate = dateRange?.from ? new Date(dateRange.from) : null;
  const toDate = dateRange?.to ? new Date(dateRange.to) : null;

  const isFromDateGreaterThanTo = fromDate && toDate && fromDate > toDate;

  return (
    <TextFieldsWrapper>
      <TextFieldWrapper>
        <CustomDateTextField
          label={t('start_date')}
          helperText={t('invalid_date')}
          dataTestId={START_DATE_TEXT_FIELD_ID}
          initialDate={dateRange?.from}
          resetDateHandler={resetStartDateHandler}
          setDateHandler={setStartDateHandler}
          setIsDateRangeValid={setIsDateRangeValid}
        />
      </TextFieldWrapper>

      <TextFieldWrapper>
        <CustomDateTextField
          label={t('end_date')}
          isHelperTextError={Boolean(isFromDateGreaterThanTo)}
          helperText={
            isFromDateGreaterThanTo
              ? t('start_date_error')
              : t('invalid_date')
          }
          dataTestId={END_DATE_TEXT_FIELD_ID}
          initialDate={dateRange?.to}
          resetDateHandler={resetEndDateHandler}
          setDateHandler={setEndDateHandler}
          setIsDateRangeValid={setIsDateRangeValid}
        />
      </TextFieldWrapper>
    </TextFieldsWrapper>
  );
};

export default CustomDateRangeTextFields;
