import { endYear, startYear } from '@features/haulhubUi/const/date.const';

export const validateDate = (rawValue: string): {
  isValid: boolean;
  formattedDate?: string;
} => {
  const hasNumber = /\d+/.test(rawValue);
  const isCompleteDate = /^\d{2}\/\d{2}\/\d{4}$/.test(rawValue);
  const [month, day, year] = rawValue.split('/').map(Number);

  const isValidDay = day >= 1 && day <= 31;
  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = year >= startYear && year <= endYear;

  const isFullValidDate = isValidDay && isValidMonth && isValidYear;

  if (isFullValidDate) {
    const inputDate = new Date(year, month - 1, day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (inputDate > today) {
      return { isValid: false };
    }

    return {
      isValid: isCompleteDate ? isFullValidDate : false,
      formattedDate: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
    };
  }

  return { isValid: hasNumber || isCompleteDate ? isFullValidDate : true };
};
