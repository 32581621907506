import { FC, memo, ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { format, isSameDay, parseISO, subMonths } from 'date-fns';
import { ChevronProps, DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import ButtonsList from '@features/haulhubUi/components/buttonsList/ButtonsList';
import MemoizedButton from '@features/haulhubUi/components/memoizedButton/MemoizedButton';
import { useFeatureFlags } from '@providers/featureFlagsProvider';
import { fFlagsNames } from '@configs/fFlagsNames/fFlagsNames';
import { EDateRangeButtons } from '@features/haulhubUi/components/dropdownDatePicker/enum/dateRangeButtons.enum';
import {
  CustomDateRangeTextFields,
} from '@features/haulhubUi/components/dropdownDatePicker/components/customDateRangeTextFields';
import {
  IDateRangeParams,
} from '@features/haulhubUi/components/dropdownDatePicker/components/customDateRangeTextFields/сustomDateRangeTextFields.interface';

import { getDropdownButtonsConfig } from '../../configs/getDropdownButtonsConfig';
import { getInitialDateRange } from '../../helpers/getInitialDateRange';
import { defaultDateRangeButtonsConfigList } from '../../configs/defaultDateRangeButtonsConfigList';
import { IDropdownDatePickerContentProps } from './dropdownDatePickerContent.interface';
import { DropdownContent } from './dropdownDatePickerContent.theme';
import { dropdownDatePickerContentTexts } from './dropdownDatePickerContent.const';

const DropdownDatePickerContent: FC<IDropdownDatePickerContentProps> = (props) => {
  const {
    dateStart,
    dateEnd,
    resetButtonTitle,
    saveButtonTitle,
    selectedChipButton,
    dateRangeButtonsConfigList = defaultDateRangeButtonsConfigList,
    closeDropdownHandler,
    changeDateRangeHandler,
    updateSelectedChipButton,
  } = props;
  const today = new Date();
  const initDateRange = selectedChipButton === EDateRangeButtons.YEAR_TO_DATE ? {
    from: dateRangeButtonsConfigList[EDateRangeButtons.YEAR_TO_DATE].from || undefined,
    to: dateRangeButtonsConfigList[EDateRangeButtons.YEAR_TO_DATE].to || undefined,
  } as DateRange : getInitialDateRange(dateStart, dateEnd);

  const { isUnderFeatureFlag } = useFeatureFlags();
  const [dateRange, setDateRange] = useState<DateRange | undefined>(initDateRange);
  const [innerSelectedChipButton, setInnerSelectedChipButton] = useState<EDateRangeButtons | null>(selectedChipButton);
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(subMonths(dateRange?.from || today, 1));
  const [isExpanded, setIsExpanded] = useState<boolean>(innerSelectedChipButton === EDateRangeButtons.CUSTOM);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(false);
  const isToDateHigherThanFrom = useMemo(() => {
    if (!dateRange) return false;

    if (dateRange.to && dateRange.from) {
      return dateRange.to < dateRange.from;
    }

    return false;
  }, [dateRange]);

  const onDateButtonClick = useCallback((value: string): void => {
    setInnerSelectedChipButton(value as EDateRangeButtons);

    if (!dateRangeButtonsConfigList[value].from) {
      setDateRange(undefined);
    }

    if (dateRangeButtonsConfigList[value].from) {
      setDateRange({
        from: dateRangeButtonsConfigList[value].from || undefined,
        to: dateRangeButtonsConfigList[value].to || undefined,
      });
    }

    setCurrentMonth(subMonths(today, 1));
    setIsExpanded(value === EDateRangeButtons.CUSTOM);
    if (value === EDateRangeButtons.CUSTOM) {
      setDateRange(undefined);
    }
  }, []);

  const disabledSaveButtonHandler = useCallback((value: boolean): void => {
    setIsDisabledSaveButton(value);
  }, []);

  const onResetButtonClick = useCallback((): void => {
    setDateRange(undefined);
    setInnerSelectedChipButton(null);
  }, []);

  const onSaveButtonClick = (): void => {
    changeDateRangeHandler(
      dateRange?.from ? format(new Date(dateRange?.from), 'yyyy-MM-dd') : null,
      dateRange?.to ? format(new Date(dateRange?.to), 'yyyy-MM-dd') : null,
    );
    updateSelectedChipButton(innerSelectedChipButton);
    closeDropdownHandler();
  };

  const onSelect = (_range: DateRange | undefined, selectedDay: Date): void => {
    if (innerSelectedChipButton !== EDateRangeButtons.CUSTOM) {
      setIsExpanded(true);
      setInnerSelectedChipButton(EDateRangeButtons.CUSTOM);
    }
    setDateRange((prevState) => {
      const isResetDateRange = !!prevState?.from && isSameDay(selectedDay, prevState.from);
      const isSetFromDate = !prevState || (!!prevState?.from && !!prevState?.to);
      const isSetToDate = !!prevState?.from && !prevState.to;
      const isFromInThePast = !!prevState?.from && selectedDay > prevState.from;
      const isFromDateButToEmpty = !prevState?.from && prevState?.to;
      const isToInTheFeature = !!prevState?.to && selectedDay > prevState.to;

      if (isResetDateRange) return undefined;

      if (isSetFromDate) {
        return {
          from: selectedDay,
          to: undefined,
        };
      }

      if (isFromDateButToEmpty && isToInTheFeature) {
        return {
          from: prevState?.to,
          to: selectedDay,
        };
      }

      if (isFromDateButToEmpty && !isToInTheFeature) {
        return {
          from: selectedDay,
          to: prevState?.to,
        };
      }

      if (isSetToDate && isFromInThePast) {
        return {
          from: prevState.from,
          to: selectedDay,
        };
      }

      if (isSetToDate && !isFromInThePast) {
        return {
          from: selectedDay,
          to: prevState.from,
        };
      }

      if (isSetToDate && !isFromInThePast) {
        return {
          from: selectedDay,
          to: prevState.from,
        };
      }

      if (isSetToDate && !isFromInThePast) {
        return {
          from: selectedDay,
          to: prevState.from,
        };
      }
      return undefined;
    });
  };

  const onMonthChange = (newMonth: Date): void => {
    setCurrentMonth(newMonth);
  };

  const customDateRangeHandler = useCallback((params: IDateRangeParams): void => {
    setDateRange((prevState): DateRange | undefined => {
      const hasFrom = 'from' in params;
      const hasTo = 'to' in params;

      const isResetDateRange =
        hasFrom && hasTo &&
        params.from === undefined && params.to === undefined;

      if (!hasFrom && !hasTo) return prevState;

      if (isResetDateRange) return undefined;

      const updatedFrom = params.from ? parseISO(params.from) : undefined;
      const updatedTo = params.to ? parseISO(params.to) : undefined;

      return {
        from: hasFrom ? updatedFrom : prevState?.from,
        to: hasTo ? updatedTo : prevState?.to,
      };
    });
  }, []);

  const renderChevron = (params: ChevronProps): ReactElement => {
    if (params.orientation === 'left') {
      return <WestIcon fontSize="small" />;
    }
    return <EastIcon fontSize="small" />;
  };

  // This new protection form task edot-2929. We don't need detect correct range and highlight correct chip when user clicked on the date picker
  /*  useEffect(() => {
      if (dateRange === undefined) {
        setInnerSelectedChipButton(null);
        return;
      }
      if (innerSelectedChipButton === EDateRangeButtons.CUSTOM) return;
      const selectedButton = getMatchedDateRangeButton(dateRange, dateRangeButtonsConfigList);
      setIsExpanded(selectedButton === EDateRangeButtons.CUSTOM);
      setInnerSelectedChipButton(selectedButton);
    }, [
      dateRangeButtonsConfigList,
      innerSelectedChipButton,
      selectedChipButton,
      dateRange,
    ]);*/

  return (
    <DropdownContent isError={isToDateHigherThanFrom}>
      <header className="dropdown-content-header">
        <ButtonsList
          buttonsConfig={getDropdownButtonsConfig(dateRangeButtonsConfigList, innerSelectedChipButton)}
          onButtonClick={onDateButtonClick}
        />

        <Collapse
          in={isExpanded}
          timeout="auto"
          unmountOnExit={true}
        >
          <CustomDateRangeTextFields
            dateRange={dateRange}
            dateRangeHandler={customDateRangeHandler}
            setIsDateRangeValid={disabledSaveButtonHandler}
          />
        </Collapse>
      </header>

      <DayPicker
        numberOfMonths={2}
        mode="range"
        month={currentMonth}
        selected={dateRange}
        disabled={{ after: today }}
        onSelect={onSelect}
        endMonth={today}
        components={{
          Chevron: renderChevron,
        }}
        onMonthChange={onMonthChange}
      />

      <footer className="dropdown-content-footer">
        {!isUnderFeatureFlag(fFlagsNames.fflagYearToDate)
          ? (
            <MemoizedButton
              variant="text"
              color="secondary"
              className="reset-button"
              onClick={onResetButtonClick}
            >

              {resetButtonTitle || dropdownDatePickerContentTexts.RESET_BUTTON}
            </MemoizedButton>
          )
          : null
        }

        <Button
          variant="text"
          color="primary"
          disabled={isDisabledSaveButton}
          onClick={onSaveButtonClick}
        >
          {saveButtonTitle || dropdownDatePickerContentTexts.SAVE_BUTTON}
        </Button>
      </footer>
    </DropdownContent>
  );
};

export default memo(DropdownDatePickerContent);
