import {
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { EDateRangeButtons } from '@features/haulhubUi/components/dropdownDatePicker/enum/dateRangeButtons.enum';

import { IDateRangeButton } from '../interfaces/dateRangeButton.interface';

const today = new Date();

export const defaultDateRangeButtonsConfigList: Record<string, IDateRangeButton> = {
  TODAY: {
    label: 'Today',
    value: EDateRangeButtons.TODAY,
    from: today,
    to: today,
  },
  THIS_WEEK: {
    label: 'This Week',
    value: EDateRangeButtons.THIS_WEEK,
    from: startOfWeek(today),
    to: today,
  },
  /* LAST_WEEK: {
     label: 'Last Week',
     value: 'LAST_WEEK',
     from: startOfWeek(subDays(today, 7)),
     to: endOfWeek(subDays(today, 7)),
   },
   LAST_SEVEN_DAYS: {
     label: 'Last 7 Days',
     value: 'LAST_SEVEN_DAYS',
     from: subDays(today, 7),
     to: subDays(today, 1),
   },*/
  CURRENT_MONTH: {
    label: 'Current Month',
    value: EDateRangeButtons.CURRENT_MONTH,
    from: startOfMonth(today),
    to: today,
  },
  CUSTOM: {
    label: 'Custom',
    value: EDateRangeButtons.CUSTOM,
    from: null,
    to: null,
  },
};
