import { styled } from '@mui/material';

import { rem } from '@helpers/remUtils';
import { alpha } from '@mui/material/styles';

export const TextFieldsWrapper = styled('div')(() => ({
  marginTop: rem(20),
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TextFieldWrapper = styled('div')(({ theme }) => ({
  width: '49%',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: alpha(theme.palette.common.white, 0.3),
  },
}));
