import { FC, useState, MouseEvent, ReactElement, useMemo, memo } from 'react';
import { Chip, Fade, ClickAwayListener, Popper } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';

import { EDateRangeButtons } from '@features/haulhubUi/components/dropdownDatePicker/enum/dateRangeButtons.enum';

import { IDropdownDatePickerProps } from './dropdownDatePicker.interface';
import {
  dropdownDatePickerChipTestId,
  dropdownDatePickerTexts,
} from './dropdownDatePicker.const';
import DropdownDatePickerContent from './components/dropdownDatePickerContent/DropdownDatePickerContent';

const DropdownDatePicker: FC<IDropdownDatePickerProps> = memo((props) => {
  const {
    dateStart,
    dateEnd,
    options,
    PopperComponent,
    changeDateRangeHandler,
    disabled,
    dateRangeButtonsConfig,
    resetInputHandler,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const initialSelectedChipButton = dateRangeButtonsConfig?.selectedValueByDefault || null;
  const [selectedChipButton, setSelectedChipButton] = useState<EDateRangeButtons | null>(initialSelectedChipButton);
  const {
    label,
    resetButtonTitle,
    saveButtonTitle,
  } = options || {};

  const CurrentPopperComponent = PopperComponent || Popper;
  const currentLabel = useMemo((): string => {
    if (dateStart && !dateEnd) {
      return format(new Date(dateStart), 'M/d/yyyy');
    }

    if (!dateStart && dateEnd) {
      return format(new Date(dateEnd), 'M/d/yyyy');
    }

    if (dateStart && dateEnd && dateEnd === dateStart) {
      return format(new Date(dateStart), 'M/d/yyyy');
    }

    if (dateStart && dateEnd) {
      return `${format(new Date(dateStart), 'M/d/yyyy')} - ${format(new Date(dateEnd), 'M/d/yyyy')}`;
    }

    if (label) {
      return label;
    }
    return dropdownDatePickerTexts.DEFAULT_LABEL;
  }, [
    dateStart,
    dateEnd,
    label,
  ]);

  const toggleIsDropdownOpenHandler = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  const closeDropdownHandler = (): void => {
    setIsDropdownOpen(false);
  };

  const resetDateRangeHandler = (): void => {
    resetInputHandler();
    setIsDropdownOpen(false);
    setSelectedChipButton(initialSelectedChipButton);
  };

  const updateSelectedChipButton = (newSelectedChipButton: EDateRangeButtons | null): void => {
    setSelectedChipButton(newSelectedChipButton);
  };

  return (
    <>
      <Chip
        icon={<CalendarMonthIcon fontSize="small" />}
        label={currentLabel}
        variant="filled"
        color={isDropdownOpen ? 'secondary' : 'default'}
        data-testid={dropdownDatePickerChipTestId}
        onClick={toggleIsDropdownOpenHandler}
        onDelete={disabled ? resetDateRangeHandler : undefined}
      />

      <CurrentPopperComponent
        open={isDropdownOpen}
        anchorEl={anchorEl}
        transition={true}
        placement="bottom-start"
      >
        {({ TransitionProps }): ReactElement => (
          <ClickAwayListener onClickAway={closeDropdownHandler}>
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              {/* Fade don`t work with styled component */}
              <div>
                <DropdownDatePickerContent
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  resetButtonTitle={resetButtonTitle}
                  saveButtonTitle={saveButtonTitle}
                  selectedChipButton={selectedChipButton}
                  dateRangeButtonsConfigList={dateRangeButtonsConfig?.buttonsConfigList}
                  closeDropdownHandler={closeDropdownHandler}
                  changeDateRangeHandler={changeDateRangeHandler}
                  updateSelectedChipButton={updateSelectedChipButton}
                />
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </CurrentPopperComponent>
    </>
  );
});

export { DropdownDatePicker };
