import { DateRange } from 'react-day-picker';

export const getInitialDateRange = (dateStart: string | null, dateEnd: string | null): DateRange | undefined => {
  if (!dateStart && !dateEnd) return undefined;

  return {
    from: dateStart ? new Date(dateStart) : undefined,
    to: dateEnd ? new Date(dateEnd) : undefined,
  };
};
