import React, { FC, useState, useCallback, useEffect, ChangeEvent, BaseSyntheticEvent } from 'react';

import { FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import { isValid as isValidDate, parseISO } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import InputMask from 'react-input-mask';

import { dateFormatter } from '@helpers/dateFormatter';
import { dateMask, dateMaskPlaceholder } from '@features/haulhubUi/const/masks.const';
import { validateDate } from '@features/haulhubUi/helpers/dateValidation.helper';
import { rem } from '@helpers/remUtils';

import { ICustomDateTextFieldProps } from './customDateTextField.interface';

const CustomDateTextField: FC<ICustomDateTextFieldProps> = (props) => {
  const {
    label,
    helperText,
    dataTestId,
    initialDate,
    isHelperTextError,
    setDateHandler,
    resetDateHandler,
    setIsDateRangeValid,
  } = props;
  const initialFormattedDate = initialDate ? dateFormatter('MM/dd/yyyy', initialDate) : null;
  const [localDate, setLocalDate] = useState<string | null>(initialFormattedDate);
  const [isDateValid, setIsDateValid] = useState(true);

  const isLocalDateEmpty = !localDate || localDate === dateMaskPlaceholder;
  const isError = !isDateValid && Boolean(localDate) && !isLocalDateEmpty;
  const value = localDate || '';

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const rawValue = event.target.value;
    setLocalDate(rawValue);

    const { isValid, formattedDate } = validateDate(rawValue);
    const isValidParsedDate =
      isValid && formattedDate ? isValidDate(parseISO(formattedDate)) : false;
    setIsDateValid(isValidParsedDate);

    if (isValidParsedDate && formattedDate) {
      setDateHandler(formattedDate);
    }
  }, []);

  const onClear = (event: BaseSyntheticEvent): void => {
    event.stopPropagation();
    resetDateHandler();
    setLocalDate(null);
    setIsDateValid(true);
  };

  setIsDateRangeValid((isHelperTextError || isError));

  useEffect(() => {
    setLocalDate(initialFormattedDate);
    if (!initialDate) return;
    const { isValid, formattedDate } = validateDate(dateFormatter('MM/dd/yyyy', initialDate));
    const isValidParsedDate =
      isValid && formattedDate ? isValidDate(parseISO(formattedDate)) : false;
    setIsDateValid(isValidParsedDate);
  }, [initialDate]);

  return (
    <TextField
      placeholder={dateMaskPlaceholder}
      data-testid={dataTestId}
      error={isHelperTextError || isError}
      value={value}
      label={label}
      size="small"
      helperText={(isHelperTextError || isError) && (
        <FormHelperText
          error={true}
          sx={{ margin: 0, fontSize: rem(11) }}
        >
          {helperText}
        </FormHelperText>
      )}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputMask as never,
        inputProps: {
          maskPlaceholder: dateMaskPlaceholder,
          onChange,
          value,
          mask: dateMask,
        },
        endAdornment: (
          <InputAdornment position="end">
            {!isLocalDateEmpty && onClear && (
              <IconButton
                aria-label="clear input"
                onClick={onClear}
                size="small"
                edge="end"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomDateTextField;
