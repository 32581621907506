export enum EDateRangeButtons {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  ALL_TIME = 'ALL_TIME',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  CUSTOM = 'CUSTOM',
}
