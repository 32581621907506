import { styled, alpha } from '@mui/material';

import { br, m, p, rem } from '@helpers/remUtils';

export const DropdownContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{isError: boolean}>(({ theme, isError }) => ({
  margin: m(5, 0, 0),
  width: rem(560),
  borderRadius: br(7),
  border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  backgroundColor: theme.palette.additionalColors.background.secondary,
  boxShadow: `0px 10px 32px 0px ${theme.palette.additionalColors.boxShadow.main}`,

  '.dropdown-content-header': {
    padding: p(14, 10),
    borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },

  '.rdp-root': {
    margin: 0,
    padding: p(0, 8, 0, 9),
    '--rdp-day-height': rem(35),
    '--rdp-day-width': rem(35),
    '--rdp-weekday-opacity': 1,
    '--rdp-weekday-text-transform': 'uppercase',
    '--rdp-months-gap': 0,
    '--rdp-nav-height': rem(28),
    '--rdp-weekday-padding': rem(5.5),
    '--rdp-selected-border': 'none',
    '--rdp-day_button-width': rem(35),
    '--rdp-day_button-height': rem(35),
    '--rdp-range_middle-background-color': !isError
      ? theme.palette.additionalColors.background.fourthly
      : 'transparent',
  },

  '.rdp-nav': {
    justifyContent: 'space-between',
    insetBlockStart: rem(18),
    insetInlineEnd: rem(19),
    width: rem(505),
  },

  '.rdp-weekday': {
    fontSize: rem(11),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  '.rdp-months': {
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },

  '.rdp-month': {
    padding: p(18, 13, 13),
    '&:not(:last-of-type)': {
      borderRight: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
    },
  },

  '.rdp-month_caption': {
    justifyContent: 'center',
  },

  '.rdp-button_previous, .rdp-button_next': {
    color: alpha(theme.palette.common.white, 0.7),
  },

  '.rdp-caption_label': {
    fontSize: rem(13),
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  '.rdp-day': {
    padding: 0,
    fontSize: rem(11),
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  '.rdp-day:not(.rdp-selected, .rdp-disabled) .rdp-day_button': {
    '&:hover': {
      backgroundColor: theme.palette.additionalColors.background.fourthly,
    },
  },

  '.rdp-today:not(.rdp-selected) .rdp-day_button': {
    border: `${rem(1)} solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
  },

  '.rdp-selected:not(.rdp-range_middle) .rdp-day_button': {
    background: theme.palette.additionalColors.background.thirdly,
    color: theme.palette.text.dark,

    '&:hover': {
      background: theme.palette.additionalColors.background.thirdly,
      color: theme.palette.text.dark,
    },
  },

  '.rdp-range_start .rdp-day_button': {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: rem(35),
      height: rem(35),
      background: `radial-gradient(circle, transparent 71%, ${theme.palette.additionalColors.background.fourthly} 10)`,
    },
  },

  '.rdp-range_end .rdp-day_button': {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: rem(35),
      height: rem(35),
      background: `radial-gradient(circle, transparent 71%, ${theme.palette.additionalColors.background.fourthly} 10)`,
    },
  },

  '.dropdown-content-footer': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: p(10),
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },

  '.reset-button': {
    marginRight: rem(5),
  },
}));
